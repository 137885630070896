import axios from "axios"
import auth0 from "@/plugins/auth0.plugin"

/**
 * Make an authorized request to the backend api.
 * If the there is no authenticated user then it will simple return an axios instance without the auth token.
 *
 * @param {import("@auth0/auth0-spa-js").Auth0Client} auth0
 * @returns {Promise<import("axios").AxiosInstance>}
 *
 * @example
 * // Using the http0 function
 * async function authenticateUser(userInfo) {
 *   try {
 *     // the $auth0 is present only inside a Vue component
 *     const response = await (await http0(this.$auth0)).post("/auth0", {...userInfo});
 *     console.log(response.data);x
 *   } catch (error) {
 *     window.devErr('Error authenticating user:', error);
 *   }
 * }
 */
export const http = async () => {
  const baseURL = import.meta.env.VITE_API_URL + "/api"
  if (auth0 && auth0.isAuthenticated.value) {
    const accessToken = await auth0.getAccessTokenSilently()
    return axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }
  return axios.create({
    withCredentials: true,
    baseURL,
  })
}

export const agentHttp = async () => {
  const baseURL = import.meta.env.VITE_CHATBOT_URL
  if (auth0 && auth0.isAuthenticated.value) {
    const accessToken = await auth0.getAccessTokenSilently()
    return axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  }
  return axios.create({
    withCredentials: true,
    baseURL,
  })
}

export const hsAgiHttp = async () => {
  const baseURL = `${import.meta.env.VITE_HSAGI_HTTP_URL}/v1`
  if (auth0 && auth0.isAuthenticated.value) {
    const accessToken = await auth0.getAccessTokenSilently()
    const instance = axios.create({
      baseURL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      timeout: 10 * 60 * 1000, // 10 mins,
      transformRequest: [(data) => {
        // Ensure data is properly stringified
        if (data && typeof data === 'object') {
          try {
            return JSON.stringify(data)
          } catch (err) {
            console.error('JSON stringify error:', err)
            throw new Error('Failed to stringify request data')
          }
        }
        return data
      }],
      transformResponse: [(data) => {
        // Ensure response is properly parsed
        if (typeof data === 'string') {
          try {
            return JSON.parse(data)
          } catch (err) {
            console.error('JSON parse error:', err)
            return { error: 'Failed to parse response data', originalData: data }
          }
        }
        return data
      }]
    })

    // Add response interceptor for better error handling
    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response?.status === 400) {
          // Check for JSON parsing errors
          if (error.response.data?.error?.includes('No JSON')) {
            error.message = 'Invalid request format. Please try again.'
          } else {
            const message = error.response.data?.message || 'Unknown AGI error'
            error.message = `AGI Request failed: ${message}`
          }
        }
        return Promise.reject(error)
      }
    )

    return instance
  }
  return axios.create({
    withCredentials: true,
    baseURL,
  })
}

// public async createSupportTicket(req: Request, res: Response) {
//     try {
//       const {
//         subject,
//         description,
//         applications_id,
//         support_tickets_statuses_id,
//         support_tickets_types_id,
//         users_id,
//         assignee_ids = [],
//         source_id,
//         links = [],
//       } = req.body;
//       //@ts-ignore
//       const added_by = req.user_id;
//       //@ts-ignore
//       const display_name = req.display_name;
//       const ticketData = {
//         subject,
//         description,
//         applications_id,
//         support_tickets_statuses_id,
//         support_tickets_types_id,
//         users_id,
//         added_by,
//         date_created: new Date(),
//         date_updated: new Date()
//       };
//       let supportTicket = await dbWriter.supportTickets.create(ticketData);
//       let assigneesData = [];
//       if (assignee_ids.length) {
//         assigneesData = assignee_ids.map((user_id: any) => ({
//           support_tickets_id: supportTicket.id,
//           users_id: user_id,
//           date_created: new Date(),
//         }));
//       }
//       const supportTicketLinksData = links.map((link: any) => ({
//         support_tickets_services_id: link.support_tickets_services_id,
//         url: link.url,
//         support_tickets_id: supportTicket.id,
//         service_reference_id: link.service_reference_id,
//         is_source: source_id == link.support_tickets_services_id ? 1 : 0,
//         date_created: new Date(),
//       }));
//       let [assigneesCreated, _, linksCreated] = await Promise.all([
//         dbWriter.supportTicketAssignees.bulkCreate(assigneesData),
//         dbWriter.supportTicketActivities.create({
//           support_tickets_id: supportTicket.id,
//           users_id: users_id,
//           activity: `Ticket Created by ${display_name}: ${subject}`,
//           date_created: new Date(),
//         }),
//         dbWriter.supportTicketLinks.bulkCreate(supportTicketLinksData),
//       ]);
//       assigneesCreated = JSON.parse(JSON.stringify(assigneesCreated));
//       linksCreated = JSON.parse(JSON.stringify(linksCreated));
//       supportTicket = JSON.parse(JSON.stringify(supportTicket));
//       new SuccessResponse("Ticket Created successfully!", {
//         //@ts-ignore
//         token: req.token,
//         ...{
//           ...supportTicket,
//           links: linksCreated,
//           assignees: assigneesCreated,
//         },
//       }).send(res);
//     } catch (e: any) {
//       ApiError.handle(new BadRequestError(e.message), res);
//     }
//   }