import { computed, ref, watch, onMounted } from "vue"
import store from "@/store"
import { GETTERS, COMMITS } from "@/store/constants"
import uiEvents from "@/configs/uiEvents"
import { eventBus } from "./eventBus.plugin"
import { agentService } from "@/services"
import { hsAgiHttp, parseError } from "@/utils"
import dayjs from "dayjs"
import { tokenManager } from '@/plugins/tokenManager'

const SELECTED_THREAD_KEY = "helloscribe.locals.user.selected_thread_id"
const UPLOADED_FILE_LIST_KEY =
  "helloscrhelloscribe.locals.user.uploaded_files_list"

const UPDATE_TYPES = {
  NEW_THREAD: "new_thread",
  CONTINUE_THREAD: "continue_thread",
  NEW_QUESTION: "new_question",
  POST_ANSWER: "post_answer",
  SKIP_CLARIFICATION: "skip_clarification",
  NEW_TASK_LIST: "new_task_list",
  NEW_SUMMARY: "new_summary",
  TASK_APPROVAL: "task_approval",
  TASK_COMPLETION: "task_completion",
  TASK_REDO: "task_redo",
  THREAD_COMPLETED: "thread_completed",
  TASK_UPDATE: "task_update",
  THREAD_UPDATE: "thread_update",
  ERROR: "error",
}

const AGI_STEP = {
  CLARIFICATION: 1,
  CREATE_TASK_LIST: 2,
  SUMMARIZATION: 3,
  APPROVAL: 4,
  TASK_EXECUTION: 5,
}

const user = computed(() => store.getters[GETTERS.USER])
const tokenInfo = computed(() => store.getters[GETTERS.TOKEN_INFO])
/** @type {import("vue").Ref<AgiThread[]>} */
const threadList = computed(() => store.getters[GETTERS.AGENT_CHAT_HISTORY])
const selectedProject = computed(() => store.getters[GETTERS.SELECTED_PROJECT])
/** @type {import("vue").Ref<AgiThread|null>} */
const selectedThread = ref(null)
const agiBusy = ref(false)
const userInput = ref("")
const allowAutoScrolling = ref(true)
const language = ref({
  input: "EN-US",
  output: "EN-US",
})
const uploadedFiles = ref([])
const uploadingFiles = ref(false)
/** @type {AbortController} */
let abortController = new AbortController()

watch(selectedThread, (t) => {
  if (!t) {
    localStorage.removeItem(SELECTED_THREAD_KEY)
    return
  }
  localStorage.setItem(SELECTED_THREAD_KEY, t.id)
})

watch(uploadedFiles, (files) => {
  localStorage.setItem(UPLOADED_FILE_LIST_KEY, JSON.stringify(files))
})

onMounted(() => {
  const cachedList = localStorage.getItem(UPLOADED_FILE_LIST_KEY)
  if (!cachedList) {
    uploadedFiles.value = []
    return
  }
  try {
    const list = JSON.parse(cachedList)
    uploadedFiles.value = list.filter(
      (item) =>
        dayjs(item.exp).isValid() &&
        dayjs(item.exp).isAfter(Date.now(), "minute"),
    )
  } catch (err) {
    uploadedFiles.value = []
  }
})

async function approveTaskList(approve = false) {
  agiBusy.value = true
  selectedThread.value.loading_summarization = true

  if (!approve) {
    selectedThread.value.tasks = []
    selectedThread.value.tasks_summary = ""
    await agiGenerate({ approve })
    return
  }
  await agiGenerate({ approve })
}

async function startIncompleteThread(id, thread = null) {
  const threads = store.getters[GETTERS.AGENT_CHAT_HISTORY]
  const t = thread || threads.find((t) => t.id === id)
  if (!t) {
    console.log("[Debug] Thread selection failed in startIncompleteThread:", {
      threadId: id,
      selectedThread: selectedThread.value,
      networkStatus: navigator.onLine ? 'online' : 'offline',
      threads: threads.length
    });
    window.devErr("Selected thread not found")
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Thread Selection Error",
      body: "Unable to find or load the selected conversation. This may happen if:\n\n" +
            "1. The conversation was deleted\n" +
            "2. Your session has expired\n" +
            "3. There was a temporary network issue\n\n" +
            "Please try:\n" +
            "1. Refresh the page\n" +
            "2. Start a new conversation\n" +
            "3. If the issue persists, clear your browser cache",
    })
    return
  }

  try {
    selectedThread.value = await agentService.getThreadById(t.id || t._id)
  } catch (err) {
    console.log("[Debug] Thread fetch failed in startIncompleteThread:", {
      threadId: t.id || t._id,
      error: err?.response?.data || err,
      networkStatus: navigator.onLine ? 'online' : 'offline',
      stack: err.stack
    });
    window.devErr(err?.response?.data || err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Selected thread not found",
      body: parseError(err),
    })
    selectedThread.value = null
    return
  }

  if (selectedThread.value.step === AGI_STEP.CLARIFICATION) {
    return
  }
  if (
    selectedThread.value.step === AGI_STEP.APPROVAL &&
    !selectedThread.value.tasks_confirmed
  ) {
    return
  }
  await agiGenerate({})
}

async function sendMsg() {
  if (!tokenManager.checkTokenBalance(1)) {
    // Show token run out modal
    if (tokenManager.isFreeTrial()) {
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, {
        message: "Please upgrade to continue",
        description:
          "You've reached the limit of your free trial. Please upgrade to continue.",
        isFreeTrial: true,
      });
    } else {
      eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, {
        message: "You ran out of tokens!",
        description:
          "You don't have enough tokens to continue using HelloScribe. Please purchase new tokens to continue.",
        isFreeTrial: false,
      });
    }
    return;
  }

  agiBusy.value = true
  const msg = userInput.value.trimEnd()
  userInput.value = ""
  if (!msg || msg.length < 2) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Please enter a text to send!",
      body: "Message length should be 2 or more characters.",
    })
    agiBusy.value = false
    return
  }

  const payload = {
    language: "English",
    objective: msg,
    answer: msg,
  }

  if (selectedThread.value) {
    payload.question_idx = selectedThread.value.clarifications.length - 1
    selectedThread.value.clarifications[payload.question_idx].answer = msg
  } else {
    selectedThread.value = {
      objective: payload.objective,
      loading_clarification: true,
      clarifications: [],
      step: AGI_STEP.CLARIFICATION,
      tasks: [],
    }
  }

  try {
    await agiGenerate(payload)
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

// Removed unused validateThreadTransition function

/**
 * Validates the payload before sending to the API
 * @param {any} payload - The payload to validate
 * @throws {Error} If the payload is invalid
 */
function validatePayload(payload) {
  // Ensure payload is a valid object
  if (!payload || typeof payload !== 'object' || Array.isArray(payload)) {
    throw new Error('Invalid payload format')
  }

  // Required fields check
  const requiredFields = ['user_id', 'update_type', 'language']
  requiredFields.forEach(field => {
    if (!payload[field]) {
      throw new Error(`Missing required field: ${field}`)
    }
  })

  // Validate user_id
  if (!payload.user_id?.trim()) {
    throw new Error("Invalid user ID")
  }

  // Validate filenames
  if (!Array.isArray(payload.filenames)) {
    payload.filenames = [] // Ensure it's always an array
  }
  
  // Validate each filename
  payload.filenames = payload.filenames.filter(filename => 
    filename && typeof filename === 'string'
  ).map(filename => filename.trim())

  // Validate update_type
  if (!Object.values(UPDATE_TYPES).includes(payload.update_type)) {
    throw new Error(`Invalid update type: ${payload.update_type}`)
  }

  // Validate language
  if (!payload.language || typeof payload.language !== 'object') {
    throw new Error("Invalid language format")
  }
  if (!payload.language.input || !payload.language.output) {
    throw new Error("Language must include input and output")
  }

  // Ensure language values are strings
  payload.language.input = String(payload.language.input).trim()
  payload.language.output = String(payload.language.output).trim()

  // Validate thread specific data
  if (payload.thread_id) {
    payload.thread_id = String(payload.thread_id).trim()
  }

  // Validate token_info if present
  if (payload.token_info) {
    if (!payload.token_info.granted || !payload.token_info.balance) {
      throw new Error("Invalid token info format")
    }
    // Ensure numeric values
    payload.token_info.granted = Number(payload.token_info.granted)
    payload.token_info.balance = Number(payload.token_info.balance)
  }

  // Clean the payload by removing undefined/null values
  Object.keys(payload).forEach(key => {
    if (payload[key] === undefined || payload[key] === null) {
      delete payload[key]
    }
  })

  // Test JSON serialization
  try {
    JSON.stringify(payload)
  } catch (err) {
    console.error('Payload serialization error:', err)
    throw new Error('Payload contains non-serializable data')
  }

  return payload
}

/**
 * Validates thread state
 * @param {any} thread - The thread to validate
 * @throws {Error} If the thread state is invalid
 */
function validateThreadState(thread) {
  if (!thread) {
    throw new Error("Thread is required")
  }

  if (!Object.values(AGI_STEP).includes(thread.step)) {
    throw new Error(`Invalid thread step: ${thread.step}`)
  }

  if (thread.id && typeof thread.id !== 'string') {
    throw new Error("Invalid thread ID format")
  }

  return thread
}

/**
 * @param {{
 *   answer: string | undefined;
 *   approve: boolean;
 *   question_idx: number | undefined;
 *   objective: string | undefined;
 *   language: {input: string; output: string}}} data
 */
async function agiGenerate(data) {
  // Clarify user and token info
  const user = store.getters[GETTERS.USER]
  if (!user || !user._id) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "No logged in user found",
      body: "Please refresh the app or login again.",
    })
    return
  }

  const currentTokenInfo = tokenManager.tokenInfo.value
  if (!currentTokenInfo || !currentTokenInfo.granted) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_ALERT_MODAL, {
      severity: "error",
      title: "Missing token information",
      body: "Please refresh the app to continue.",
    })
    return
  }

  agiBusy.value = true
  renderLoadingUi()

  // Build base payload with enhanced validation
  const payload = {
    ...data,
    filenames: (uploadedFiles.value || [])
      .filter(f => f && f.filename && typeof f.filename === 'string')
      .map(f => f.filename.trim()),
    user_id: user._id?.trim(),
    token_info: currentTokenInfo ? {
      granted: currentTokenInfo.granted,
      balance: currentTokenInfo.balance
    } : null,
    language: {
      input: language.value.input,
      output: language.value.output
    }
  }

  // Store original thread state for rollback
  const originalThreadState = selectedThread.value ? 
    JSON.parse(JSON.stringify(selectedThread.value)) : null;

  let retryCount = 0;
  const maxRetries = 3;
  const baseDelay = 1000;

  while (retryCount < maxRetries) {
    try {
      // Enhanced pre-request validation
      if (!navigator.onLine) {
        throw new Error('No internet connection. Please check your network and try again.');
      }

      // Validate thread state and transitions
      if (selectedThread.value) {
        validateThreadState(selectedThread.value);
        console.log("[Debug] Pre-request thread state:", {
          threadId: selectedThread.value.id,
          step: selectedThread.value.step,
          completed: selectedThread.value.completed,
          updateType: payload.update_type
        });
      } else if (payload.thread_id) {
        // Attempt to fetch missing thread from server
        try {
          console.log("[Debug] Fetching missing thread:", {
            threadId: payload.thread_id,
            attempt: retryCount + 1
          });
          const thread = await agentService.getThreadById(payload.thread_id);
          if (thread && thread.id) {
            selectedThread.value = thread;
            store.commit(COMMITS.UPDATE_THREAD, thread);
            console.log("[Debug] Successfully recovered thread:", {
              threadId: thread.id,
              step: thread.step
            });
          } else {
            throw new Error('Thread not found on server');
          }
        } catch (fetchErr) {
          console.error("[Debug] Failed to fetch missing thread:", {
            threadId: payload.thread_id,
            error: fetchErr.message
          });
          throw new Error('Unable to recover thread. Please refresh the page or start a new conversation.');
        }
      }

      if (selectedThread.value && selectedThread.value.id) {
        payload.thread_id = selectedThread.value.id
        payload.language = selectedThread.value.language ? {
          input: selectedThread.value.language.input,
          output: selectedThread.value.language.output
        } : {
          input: language.value.input,
          output: language.value.output
        }
      } else {
        payload.thread_id = ""
        payload.update_type = UPDATE_TYPES.NEW_THREAD
        payload.language = JSON.parse(JSON.stringify(language.value))
        payload.project_id = selectedProject.value?._id || null
      }

      // Validate payload before sending
      validatePayload(payload)

      // Make request with timeout and retry handling
      abortController = new AbortController()
      
      // Create timeout promise
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Request timeout')), 60000);
      });

      // Create fetch promise with retry logic
      const fetchPromise = (await hsAgiHttp()).post(
        "/agi/generate",
        payload,
        {
          signal: abortController.signal,
          headers: {
            "Content-Type": "application/json",
          }
        }
      );

      // Race between timeout and fetch
      const res = await Promise.race([fetchPromise, timeoutPromise]);

      // Reset the file list after there's a valid response
      uploadedFiles.value = []

      // Token info updates
      const updatedTokenInfo = res.data.data?.tokenInfo
      if (updatedTokenInfo) {
        tokenManager.updateTokenInfo(updatedTokenInfo)
      }

      // Check the returned thread
      const thread = res.data.data?.thread
      if (!thread) {
        throw new Error("No thread returned from the server. Please refresh and try again.")
      }

      selectedThread.value = thread
      setTimeout(autoScroll, 500)

      // If the thread is at certain steps, end or proceed
      if (
        selectedThread.value.step === AGI_STEP.CLARIFICATION ||
        selectedThread.value.step === AGI_STEP.APPROVAL ||
        selectedThread.value.completed
      ) {
        agiBusy.value = false
        if (selectedThread.value.completed) {
          const newList = threadList.value.filter((t) => t.id !== thread.id)
          newList.unshift(thread)
          store.commit(COMMITS.SET_AGENT_CHAT_HISTORY, newList)
        }
        return
      }

      // If not completed, continue
      await agiGenerate({})
      return // Success, exit the retry loop

    } catch (err) {
      retryCount++;

      // Log detailed error information
      console.error("[agiGenerate] Request error:", {
        status: err?.response?.status,
        data: err?.response?.data,
        message: err.message,
        payload: payload,
        attempt: retryCount,
        networkStatus: navigator.onLine ? 'online' : 'offline',
        threadState: {
          selectedThreadId: selectedThread.value?.id,
          threadStep: selectedThread.value?.step,
          threadExists: Boolean(selectedThread.value),
          validationError: err.message === "Thread is required" || err.message === "Invalid thread step",
          storeThreadCount: threadList.value?.length || 0
        }
      });

      // Enhanced retry conditions with thread state validation
      const isThreadError = err.message.includes('Thread');
      const isNetworkError = err.code === 'ERR_NETWORK' || 
                            err.message === 'Request timeout' ||
                            err.response?.data?.error === "504 Deadline Exceeded";
      const isValidationError = err.response?.status === 400;
      
      // Only retry network errors and some validation errors, but not thread state errors
      const shouldRetry = retryCount < maxRetries && (
        isNetworkError || (isValidationError && !isThreadError)
      );

      if (shouldRetry) {
        // Exponential backoff with jitter
        const jitter = Math.random() * 1000;
        const delay = baseDelay * Math.pow(2, retryCount - 1) + jitter;
        
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "warning",
          text: `Network issue detected. Retrying in ${Math.round(delay/1000)} seconds... (Attempt ${retryCount}/${maxRetries})`,
        });
        
        await new Promise(resolve => setTimeout(resolve, delay));
        continue;
      }

      // If we've exhausted retries or hit a non-retryable error
      setTimeout(autoScroll, 500)
      agiBusy.value = false

      // Restore original thread state if available
      if (originalThreadState && payload.update_type === UPDATE_TYPES.NEW_THREAD) {
        selectedThread.value = originalThreadState;
      }

      // Specific error handling
      if (err.name === "CanceledError") {
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          text: "Thread canceled",
          severity: "error",
        })
      } else if (!navigator.onLine) {
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "error",
          text: "No internet connection. Please check your network and try again.",
        });
      } else if (err?.response?.status === 400) {
        // Check for specific 400 error cases
        const errorMessage = err.response?.data?.message || err.response?.data?.error;
        if (errorMessage?.toLowerCase().includes('refresh')) {
          eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
            severity: "error",
            text: "Session expired. Please refresh the app and try again.",
          })
        } else {
          eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
            severity: "error",
            text: errorMessage || "Server reported a validation error. Please check input and try again.",
          })
        }
      } else if (
        err?.response?.data?.error === "TOKEN_RAN_OUT" ||
        err?.response?.data?.error === "Unable to consume tokens"
      ) {
        const isFreeTrial = tokenManager.isFreeTrial()
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOKEN_RAN_OUT_MODAL, {
          message: isFreeTrial ? "Please upgrade to continue" : "You ran out of tokens!",
          description: isFreeTrial
            ? "You've reached the limit of your free trial. Please upgrade to continue."
            : "You don't have enough tokens to continue using HelloScribe. Please purchase new tokens to continue.",
          isFreeTrial,
        })
        if (err.response?.data?.partialResults) {
          tokenManager.handleTokenDepletionMidGeneration(
            err.response?.data?.partialResults,
            isFreeTrial
          )
        }
      } else {
        // Enhanced error messages with more context and attempt tracking
        let errorContext;
        if (err.message.includes("Thread")) {
          errorContext = `Thread selection failed (attempt ${retryCount + 1}/${maxRetries}). Please try: 1) Refresh the page, or 2) Start a new conversation`;
        } else if (!navigator.onLine) {
          errorContext = `Network connection lost. Please check your internet and try again.`;
        } else {
          errorContext = `Network error occurred (attempt ${retryCount + 1}/${maxRetries}). Please try again.`;
        }
        
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "error",
          text: errorContext,
          duration: 5000 // Show longer for more complex messages
        })
      }

      throw err; // Re-throw the error after handling
    }
  }

  // If we've exhausted all retries
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "error",
    text: "Failed to complete request after multiple attempts. Please try again later.",
  });
}

async function redoTask(thread, taskId) {
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    severity: "success",
    text: "Requesting a task redo, please wait...",
  })

  agiBusy.value = true
  selectedThread.value = thread
  selectedThread.value.step = AGI_STEP.TASK_EXECUTION
  selectedThread.value.completed = false

  for (let i = 0; i < selectedThread.value.tasks.length; i++) {
    if (selectedThread.value.tasks[i].id === taskId) {
      selectedThread.value.tasks[i].result = ""
      selectedThread.value.tasks[i].status = "In Progress"
      break
    }
  }

  await agiGenerate({
    task_id: taskId,
    instruction: "",
    update_type: UPDATE_TYPES.TASK_REDO,
  })
}

const startNewTask = () => {
  selectedThread.value = null
  eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
    text: "Your chat has been moved to the completed chat histories list.",
  })
}

function autoScroll() {
  // if (!allowAutoScrolling.value) return
  window.scrollTo({
    top: window.innerHeight * 100,
    left: 0,
    behavior: "smooth",
  })
}

function renderLoadingUi() {
  if (!selectedThread.value) {
    return
  }
  if (selectedThread.value.step === AGI_STEP.CLARIFICATION) {
    selectedThread.value.loading_clarification = true
  } else if (selectedThread.value.step === AGI_STEP.CREATE_TASK_LIST) {
    selectedThread.value.loading_task_creation = true
  } else if (selectedThread.value.step === AGI_STEP.SUMMARIZATION) {
    selectedThread.value.loading_summarization = true
  }
}

function clearCanvas() {
  if (abortController) {
    abortController.abort("User stopped the AGI.")
  }
  selectedThread.value = null
  agiBusy.value = false
}

async function getLastSelectedThread() {
  const lastSelectedThreadId = localStorage.getItem(SELECTED_THREAD_KEY)
  if (!lastSelectedThreadId) return

  const maxRetries = 3
  let retries = 0

  while (retries < maxRetries) {
    try {
      const t = await agentService.getThreadById(lastSelectedThreadId)
      if (t && t.id) {
        if (t.completed) {
          localStorage.removeItem(SELECTED_THREAD_KEY)
        } else {
          selectedThread.value = t
          await startIncompleteThread(t.id, t)
        }
      }
      return // Success, exit the function
    } catch (err) {
      retries++
      console.error(`Attempt ${retries} failed:`, err)

      if (retries >= maxRetries || err.response) {
        // If we've reached max retries or received a response (even if it's an error), stop retrying
        console.log("[Debug] Thread fetch failed in getLastSelectedThread:", {
          threadId: lastSelectedThreadId,
          attempts: retries,
          error: err.response || err,
          networkStatus: navigator.onLine ? 'online' : 'offline',
          stack: err.stack
        });
        window.devErr(`Failed to fetch thread after ${retries} attempts:`, err.response || err)
        localStorage.removeItem(SELECTED_THREAD_KEY)
        eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
          severity: "error",
          text: "Failed to load the last selected thread. Please try again later.",
        })
        return
      }

      // If it's a network error, wait before retrying
      if (err.code === 'ERR_NETWORK') {
        await new Promise(resolve => setTimeout(resolve, 2000 * retries)) // Exponential backoff
      }
    }
  }
}

async function skipClarificationStep() {
  const payload = {
    update_type: UPDATE_TYPES.SKIP_CLARIFICATION,
    user_id: user.value._id,
    thread_id: selectedThread.value.id,
    language: selectedThread.value.language,
    token_info: tokenInfo.value,
  }
  await agiGenerate(payload)
}

/**
 * @param {File[]} fileList
 */
async function uploadFiles(fileList) {
  if (fileList.length < 1) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: "No files selected",
      severity: "error",
    })
    return
  }
  try {
    uploadingFiles.value = true
    const form = new FormData()
    fileList.forEach((f) => {
      form.append("files", f)
    })
    const res = await (
      await hsAgiHttp()
    ).post("/agi/generate/upload-file", form, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    if (
      res.data.data &&
      "length" in res.data.data &&
      res.data.data.length > 0
    ) {
      uploadedFiles.value.push(...res.data.data)
    }
    store.commit(COMMITS.SET_SHOW_QUICK_STARTS, false)
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: parseError(err),
      severity: "error",
    })
  } finally {
    uploadingFiles.value = false
  }
}

async function removeUploadedFile(file) {
  try {
    uploadingFiles.value = true
    const filename = encodeURIComponent(file.filename)
    await (await hsAgiHttp()).delete(`/agi/generate/upload-file/${filename}`)
    uploadedFiles.value = uploadedFiles.value.filter(
      (f) => f.filename !== file.filename,
    )
  } catch (err) {
    window.devErr(err)
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      text: parseError(err),
      severity: "error",
    })
  } finally {
    uploadingFiles.value = false
  }
}

async function newQuestion(question) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!question || question.length < 2) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Question is too short",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.NEW_QUESTION,
      question,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  } finally {
    agiBusy.value = false
  }
}

async function createNewTaskList() {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  agiBusy.value = true
  selectedThread.value.loading_task_creation = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.NEW_TASK_LIST,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function generateNewSummary() {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  agiBusy.value = true
  selectedThread.value.loading_summarization = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.NEW_SUMMARY,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function completeTask(taskId) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!taskId) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "No task specified",
    })
    return
  }

  const task = selectedThread.value.tasks.find(t => t.id === taskId)
  if (!task) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Task not found",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.TASK_COMPLETION,
      task_id: taskId,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function updateTask(taskId, updates) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!taskId || !updates) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Invalid task update parameters",
    })
    return
  }

  const task = selectedThread.value.tasks.find(t => t.id === taskId)
  if (!task) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Task not found",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.TASK_UPDATE,
      task_id: taskId,
      updates,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

async function updateThread(updates) {
  if (!selectedThread.value) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "Please select a thread first",
    })
    return
  }

  if (!updates) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: "No updates provided",
    })
    return
  }

  agiBusy.value = true
  try {
    await agiGenerate({
      update_type: UPDATE_TYPES.THREAD_UPDATE,
      updates,
      thread_id: selectedThread.value.id
    })
  } catch (err) {
    eventBus.emit(uiEvents.GLOBAL.SHOW_TOAST, {
      severity: "error",
      text: parseError(err),
    })
    window.devErr(err.response?.data || err)
  }
}

/************* the plugin **************/
export default {
  /**
   *  @param {import("vue").App} app
   */
  install(app) {
    const hsAgi = {
      threadList,
      selectedThread,
      agiBusy,
      userInput,
      allowAutoScrolling,
      language,
      uploadedFiles,
      uploadingFiles,

      UPDATE_TYPES,
      AGI_STEP,

      approveTaskList,
      redoTask,
      sendMsg,
      startIncompleteThread,
      startNewTask,
      clearCanvas,
      getLastSelectedThread,
      skipClarificationStep,
      uploadFiles,
      removeUploadedFile,

      appendToChat(partialResults) {
        if (Array.isArray(partialResults)) {
          partialResults.forEach(result => {
            if (selectedThread.value && selectedThread.value.tasks) {
              const lastTask = selectedThread.value.tasks[selectedThread.value.tasks.length - 1];
              if (lastTask) {
                lastTask.result = (lastTask.result || '') + result;
              }
            }
          });
        } else if (typeof partialResults === 'object' && partialResults.text) {
          if (selectedThread.value && selectedThread.value.tasks) {
            const lastTask = selectedThread.value.tasks[selectedThread.value.tasks.length - 1];
            if (lastTask) {
              lastTask.result = (lastTask.result || '') + partialResults.text;
            }
          }
        }
        // Trigger a re-render or emit an event to update the UI
        eventBus.emit(uiEvents.GLOBAL.UPDATE_CHAT_INTERFACE);
      },

      newQuestion,
      createNewTaskList,
      generateNewSummary,
      completeTask,
      updateTask,
      updateThread,
    }

    app.provide("hsAgi", hsAgi)
    app.config.globalProperties.$hsAgi = hsAgi
  },
}
